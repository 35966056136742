import * as React from "react"
import { Link, graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Blog = ({ data, pageContext }) => {
  const breakpoints = useBreakpoint()

  return (
    <>
      <Layout
        headerComponent={
          <header className="header pt-8 pb-4 text-white bg-dark text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <h1>{data.strapiBlog.Title}</h1>
                  <p className="lead-2 opacity-90 mt-6">
                    {data.strapiBlog.Description}
                  </p>
                </div>
              </div>
            </div>
          </header>
        }
      >
        <section className="section py-7">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ul className="nav nav-tabs-outline nav-separated mx-n2 mx-md-0 mb-3 mb-md-7 mt-n6 mt-md-0 justify-content-start">
                  <li className="nav-item pl-md-0 pr-md-0">
                    <Link
                      className={`${
                        breakpoints.md ? "nav-link fs-14" : "nav-link fs-16"
                      }`}
                      to={`/blog`}
                      activeClassName="active"
                      style={{ borderRadius: "2px" }}
                    >
                      All
                    </Link>
                  </li>
                  {data.allStrapiCategory.edges.map(
                    ({ node: { Name, Slug } }, index) => (
                      <li className="nav-item pr-md-0" key={index}>
                        <Link
                          className={`${
                            breakpoints.md ? "nav-link fs-14" : "nav-link fs-16"
                          }`}
                          to={`/blog/${Slug}`}
                          activeClassName="active"
                          style={{ borderRadius: "2px" }}
                        >
                          {Name}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <div className="row gap-y">
              {data.allStrapiArticle.edges.map(
                (
                  {
                    node: { id, Title, published_at, Slug, Categories, Cover },
                  },
                  index
                ) => (
                  <div className="col-md-6 col-lg-4" key={index}>
                    <div className="card d-block border hover-shadow-6 mb-6">
                      {Cover && (
                        <a href={`/${Slug}`}>
                          <img
                            className="card-img-top"
                            src={Cover.childImageSharp.fluid.src}
                            alt={Title}
                            style={{
                              width: "100%",
                              height: "230px",
                              objectFit: "cover",
                            }}
                          />
                        </a>
                      )}
                      <div className="p-6 text-center">
                        <p>
                          {Categories.map(({ Name }, index) => (
                            <a
                              key={index}
                              className="small-5 text-lighter text-uppercase ls-2 fw-400"
                              href={`/${Slug}`}
                              style={{ marginLeft: "2px", marginRight: "2px" }}
                            >
                              {Name}
                            </a>
                          ))}
                        </p>
                        <h4
                          className="mb-0"
                          style={{
                            height: "140px",
                          }}
                        >
                          <a className="text-dark" href={`/${Slug}`}>
                            {Title}
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <nav className="flexbox mt-6">
              <a
                className={`btn btn-white ${
                  pageContext.nextPagePath === "" ? "disabled" : ""
                }`}
                href={pageContext.nextPagePath}
              >
                <i className="ti-arrow-left fs-9 mr-2" /> Older
              </a>
              <a
                className={`btn btn-white ${
                  pageContext.previousPagePath === "" ? "disabled" : ""
                }`}
                href={pageContext.previousPagePath}
              >
                Newer <i className="ti-arrow-right fs-9 ml-2" />
              </a>
            </nav>
          </div>
        </section>
      </Layout>
      <Seo
        title="Trustic Blog | Welcome!"
        description="A place to share ideas and news from our community of borrowers and guarantors."
      />
    </>
  )
}

export default Blog

export const blogListQuery = graphql`
  query blogListQuery(
    $filter: StrapiArticleFilterInput
    $skip: Int!
    $limit: Int!
  ) {
    allStrapiArticle(
      filter: $filter
      sort: { fields: [createdAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          Title
          published_at
          Slug
          Cover {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          Categories {
            id
            Name
            Slug
          }
          Content {
            Image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            LeadParagraph
            NormalParagraph
            BlockquoteCite
            BlockquoteText
          }
        }
      }
    }
    strapiBlog {
      id
      Title
      Description
    }
    allStrapiCategory {
      edges {
        node {
          id
          Name
          Slug
        }
      }
    }
  }
`
